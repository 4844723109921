<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 63 63"
    :class="data.staticClass"
  >
    <path
      :fill="props.color"
      d="M61.77 13.781H59.2l-3.76-3.651a12.256 12.256 0 00-8.587-3.486h-3.784L40.484 3.89a12.319 12.319 0 00-17.968 0l-2.585 2.755h-3.784a12.255 12.255 0 00-8.586 3.485L3.8 13.781H1.23A1.231 1.231 0 000 15.012v7.875a1.231 1.231 0 001.23 1.23h2.9l3.207 23.625H5.206a1.231 1.231 0 00-1.23 1.23v7.875a1.231 1.231 0 001.23 1.23h4.849a5.168 5.168 0 0010.323 0h22.243a5.168 5.168 0 0010.323 0h4.849a1.231 1.231 0 001.23-1.23v-7.874a1.231 1.231 0 00-1.23-1.23h-2.13l3.206-23.625h2.9A1.231 1.231 0 0063 22.887v-7.875a1.231 1.231 0 00-1.23-1.23zM9.276 11.895a9.807 9.807 0 016.871-2.789h4.317a1.23 1.23 0 00.9-.388l2.949-3.143a9.858 9.858 0 0114.379 0l2.949 3.143a1.23 1.23 0 00.9.388h4.317a9.807 9.807 0 016.872 2.789l1.94 1.886H7.335zM6.615 24.117H9.84l3.206 23.625H9.82zm8.914 23.625l-.86-6.337h33.662l-.86 6.337zm33.136-8.8h-34.33l-.835-6.181h36zM15.217 60.539a2.707 2.707 0 112.707-2.707 2.71 2.71 0 01-2.707 2.707zm32.566 0a2.707 2.707 0 112.707-2.707 2.71 2.71 0 01-2.707 2.707zm8.78-4.922h-4.112a5.166 5.166 0 00-9.337 0H19.885a5.166 5.166 0 00-9.337 0H6.437V50.2h50.126zm-3.383-7.875h-3.225l3.205-23.625h3.225zm7.359-26.086h-23.5a1.23 1.23 0 000 2.461h13.64l-.841 6.183H13.162l-.839-6.183h13.64a1.23 1.23 0 000-2.461H2.461v-5.414h58.078z"
    />
    <path
      :fill="props.color"
      d="M31.5 21.656a1.231 1.231 0 10.87.361 1.239 1.239 0 00-.87-.361z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#000',
      type: String,
    }
  }
}
</script>
