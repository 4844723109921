<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.537"
    height="18.973"
    viewBox="0 0 21.537 18.973"
    :class="data.staticClass"
  >
    <g
      id="Group_232"
      data-name="Group 232"
      transform="translate(-15.75 -19.85)"
    >
      <g
        id="Group_226"
        data-name="Group 226"
        transform="translate(16 20.1)"
      >
        <path
          id="Path_32"
          data-name="Path 32"
          d="M22.178,20.1a2.752,2.752,0,0,0-2.626,1.977H16.741a.739.739,0,0,0-.741.772.765.765,0,0,0,.772.772h2.811A2.724,2.724,0,0,0,22.209,25.6a2.762,2.762,0,0,0,2.657-1.977h11.4a.772.772,0,1,0,0-1.545h-11.4A2.828,2.828,0,0,0,22.178,20.1Zm.031,1.545a1.2,1.2,0,1,1-1.2,1.2A1.206,1.206,0,0,1,22.209,21.645Z"
          transform="translate(-16 -20.1)"
          :stroke="props.color"
          stroke-width="0.5"
        />
      </g>
      <g
        id="Group_227"
        data-name="Group 227"
        transform="translate(16 26.278)"
      >
        <path
          id="Path_33"
          data-name="Path 33"
          d="M31.477,40.1a2.752,2.752,0,0,0-2.626,1.977H16.772a.746.746,0,0,0-.772.772.765.765,0,0,0,.772.772H28.851A2.724,2.724,0,0,0,31.477,45.6a2.762,2.762,0,0,0,2.657-1.977h2.132a.772.772,0,1,0,0-1.545H34.133A2.762,2.762,0,0,0,31.477,40.1Zm.031,1.545a1.2,1.2,0,1,1-1.2,1.2A1.187,1.187,0,0,1,31.508,41.645Z"
          transform="translate(-16 -40.1)"
          :stroke="props.color"
          stroke-width="0.5"
        />
      </g>
      <g
        id="Group_228"
        data-name="Group 228"
        transform="translate(16 33.074)"
      >
        <path
          id="Path_34"
          data-name="Path 34"
          d="M25.885,62.1a2.752,2.752,0,0,0-2.626,1.977H16.772a.746.746,0,0,0-.772.772.765.765,0,0,0,.772.772H23.29A2.724,2.724,0,0,0,25.916,67.6a2.762,2.762,0,0,0,2.657-1.977h7.692a.772.772,0,1,0,0-1.545H28.542A2.762,2.762,0,0,0,25.885,62.1Zm.031,1.545a1.2,1.2,0,1,1-1.2,1.2A1.226,1.226,0,0,1,25.916,63.645Z"
          transform="translate(-16 -62.1)"
          :stroke="props.color"
          stroke-width="0.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#000',
      type: String,
    },
  },
}
</script>
