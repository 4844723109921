var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'ratings-indicator',
    { 'ratings-indicator--selected': _vm.selected },
    { 'ratings-indicator--disabled': !_vm.ratingHasEntities(_vm.rating) }
  ]},[_c('span',{class:[
      'ratings-indicator__rating',
      _vm.ratingClass
    ]}),_vm._v(" "),_c('h5',{staticClass:"ratings-indicator__heading"},[_vm._v("\n    "+_vm._s(_vm.indicatorText)+"\n  ")]),_vm._v(" "),_c('bar',{attrs:{"bar":_vm.getBarConfig()}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }