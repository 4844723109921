<template>
  <div
    :class="{
      'portfolio-generator-toggler--invalid': errors.any()
    }"
    class="portfolio-generator-toggler"
  >
    <div class="portfolio-generator-toggler__title">
      {{ title }}
      <DashboardTooltip
        v-if="invalid"
        class="portfolio-generator-toggler__tooltip"
        type="warning"
        :tooltip="invalidCompanyWarning"
      />
    </div>
    <button
      class="portfolio-generator-toggler__button portfolio-generator-toggler__button--edit"
      :title="$t('labels.edit')"
      @click="onEditClick"
    >
      <icon-pencil class="portfolio-generator-toggler__icon-edit" />
    </button>
    <button
      class="portfolio-generator-toggler__button portfolio-generator-toggler__button--delete"
      :title="$t('labels.delete')"
      @click="onDeleteClick"
    >
      <icon-close />
    </button>
  </div>
</template>

<script>
import ErrorBag from '~/classes/ErrorBagV2'
import IconClose from '~/icons/IconClose'
import IconPencil from '~/icons/IconPencil'
import DashboardTooltip from '~/components/dashboard/DashboardTooltip'

export default {
  components: {
    IconClose,
    IconPencil,
    DashboardTooltip
  },

  props: {
    errors: {
      type: Object,
      default: () => new ErrorBag()
    },
    title: {
      type: [Promise, String],
      default: undefined
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    invalidCompanyWarning () {
      return this.$t('biodiversity_goals.portfolio.forms.mining.invalid_company_warning')
    }
  },

  methods: {
    onEditClick () {
      this.$emit('edit')
    },

    onDeleteClick () {
      this.$emit('delete')
    }
  }
}
</script>
