<template>
  <div class="item-padding--sides">
    <div class="listing__bar">
      <slot/>
    </div>

    <div class="listing__main">
      
      <listing-page-list
        :results="newResults.results"
        :template="template"
      />

      <pagination
        :currentPage="newResults.currentPage"
        :itemsPerPage="newResults.itemsPerPage"
        :totalItems="newResults.total"
        :totalPages="newResults.totalPages"
        @update-page="updateRequestedPage($event)"
      />
    </div>
  </div>
</template>

<script>
import ListingPageList from './ListingPageList.vue'
import Pagination from '../pagination/Pagination.vue'

export default {
  name: 'ListingPage',

  components: { ListingPageList, Pagination },
  
  props: {
    endpointSearch: {
      required: true,
      type: String
    },
    itemsPerPage: {
      default: 6,
      type: Number
    },
    pageId: {
      required: true,
      type: Number
    },
    results: {
      required: true,
      type: Object
    },
    template: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      activeFilterOptions: {},
      newResults: this.results
    }
  },

  created () {
    this.$on('select-tab', this.getFilteredResultsByCategory)
    this.$on('select-filter', this.getFilteredResultsByYear)
    this.$on('update-page', this.updateRequestedPage)
  },

  methods: {
    updateResults (requestedPage=1) {
      let filters = {...this.activeFilterOptions, ...{ ancestor: this.pageId, template: this.template }}
      let data = {
        params: {
          filters: filters,
          items_per_page: this.itemsPerPage,
          requested_page: requestedPage
        }
      }

      this.$http.get(this.endpointSearch, data)
        .then(response => {
          this.updateProperties(response)
        })
        .catch(function (error) {
          console.log('error', error)
        })
    },

    getFilteredResultsByCategory(tab) {
      this.activeFilterOptions[tab.groupId] = tab.tabId
      this.updateResults()
    },

    getFilteredResultsByYear(year) {
      this.activeFilterOptions['year'] = year
      this.updateResults()
    },

    updateProperties (response) {
      this.newResults = response.data
    },

    updateRequestedPage (event) {
      this.updateResults(event.requestedPage)
    }
  }
}
</script>
