<template>
  <div 
    class="pagination"
  >
    <div 
      v-show="haveResults"
      class="pagination__content"
    >
      <span class="pagination__numbers">
        {{ paginationLabel() }}
      </span>

      <button
        class="pagination__button pagination__button--previous"
        :class="{ 'pagination__button--disabled': !previousIsActive }"
        @click="changePage(previousIsActive, 'previous')"
      >
        <IconChevron class="pagination__button--svg" />
      </button>

      <button
        class="pagination__button pagination__button--next"
        :class="{ 'pagination__button--disabled' : !nextIsActive }"
        @click="changePage(nextIsActive, 'next')"
      >
        <IconChevron class="pagination__button--svg" />
      </button>
    </div>
  </div>
</template>

<script>
import { range } from 'lodash'
import IconChevron from '../../icons/IconChevron.vue'

export default {
  name: "Pagination",

  components: { IconChevron },

  props: {
    currentPage: {
      required: true,
      type: Number
    },

    itemsPerPage: {
      required: true,
      type: Number
    },

    totalItems: {
      required: true,
      type: Number
    },

    totalPages: {
      required: true,
      type: Number
    }
  },

  computed: {
    previousIsActive () {
      return this.currentPage > 1
    },

    nextIsActive () {
      return this.currentPage < this.totalPages
    },

    firstItem () {
      let first

      if (this.totalItems === 0) {
        first = 0
      } else if (this.totalItems < this.itemsPerPage) {
        first = 1
      } else {
        first = this.itemsPerPage * (this.currentPage - 1) + 1
      }

      return first
    },

    lastItem () {
      let lastItem = this.itemsPerPage * this.currentPage

      if (lastItem > this.totalItems) {
        lastItem = this.totalItems
      }

      return lastItem
    },

    haveResults () {
      return this.totalItems > 0
    },
  },

  methods: {
    changePage (isActive, direction) {
      if (!isActive) return // only change the page if the button is active
      
      const newPage = direction == 'next' ? this.currentPage + 1 : this.currentPage - 1

      const obj = { 
        requestedPage: newPage 
      }

      this.$emit('update-page', obj)
    },

    goToPage (page) {
      const obj = {
        requestedPage: page,
      };
      this.$emit('update-page', obj)
    },

    paginationLabel() {
      return this.$t('pagination.label', { currentPage: this.currentPage, totalPages: this.totalPages })
    }
  },
}
</script>