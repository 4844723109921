<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53.451 67.282"
    :class="data.staticClass"
  >
    <path 
      :fill="props.color" 
      d="M45.477 7.199h-7.074a7.9 7.9 0 00-7.863-7.2h-7.645a7.914 7.914 0 00-7.863 7.2H7.96A7.977 7.977 0 000 15.104v6.05a2.435 2.435 0 002.423 2.381h2.838v35.842a7.861 7.861 0 007.835 7.9h27.259a7.87 7.87 0 007.836-7.9V23.535h2.838a2.435 2.435 0 002.423-2.381v-6.05a8 8 0 00-7.975-7.905zM22.895 4.707h7.642a3.236 3.236 0 013.115 2.491H19.783a3.236 3.236 0 013.112-2.491zm20.573 54.67a3.15 3.15 0 01-3.129 3.2H13.095a3.15 3.15 0 01-3.129-3.2V23.535h33.5zm5.261-40.549H4.706v-3.724a3.256 3.256 0 013.253-3.2h37.518a3.256 3.256 0 013.253 3.2z" 
    />
    <path 
      :fill="props.color" 
      d="M33.779 54.906a2.351 2.351 0 002.353-2.353V31.786a2.353 2.353 0 10-4.707 0v20.766a2.351 2.351 0 002.354 2.354zM19.658 54.906a2.351 2.351 0 002.353-2.353V31.786a2.353 2.353 0 10-4.706 0v20.766a2.351 2.351 0 002.353 2.354z" 
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#000',
      type: String,
    },
  },
}
</script>
