var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard',_vm._b({staticClass:"dashboard--agriculture",scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('icon-agriculture')]},proxy:true},{key:"body",fn:function(){return [(_vm.forPdf)?_c('agriculture-details',{attrs:{"in-modal":false}}):_vm._e(),_vm._v(" "),_c('exposure-results',{attrs:{"data":_vm.data.exposureResults,"bio-module":"agriculture","for-pdf":_vm.forPdf}}),_vm._v(" "),_c('agri-global-overview',{staticClass:"pdf-break-inside-avoid",attrs:{"data":_vm.data.globalOverview}}),_vm._v(" "),_c('portfolio-options',{staticClass:"pdf-break-inside-avoid",attrs:{"module":_vm.module,"initial-portfolio-options":_vm.data.alignmentOptions,"lock-portfolio-options":_vm.forPdf,"alignment-option-filter-selections":_vm.data.alignmentOptionFilterSelections}})]},proxy:true},{key:"appends",fn:function(){return [_c('dashboard-modal',{model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('template',{slot:"header"},[_c('h2',{staticClass:"dashboard-modal__title"},[_vm._v("\n          "+_vm._s(_vm.$t('portfolios.details.title'))+"\n        ")])]),_vm._v(" "),_c('template',{slot:"body"},[_c('agriculture-details')],1)],2),_vm._v(" "),_c('div',{staticClass:"\n        portfolio-generator-modal\n        portfolio-generator-modal--biodiversity-goals\n        portfolio-generator-modal--sector-selected\n      "},[_c('ModalFull',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('Generator',{attrs:{"selected-sector":"agriculture"},model:{value:(_vm.forms),callback:function ($$v) {_vm.forms=$$v},expression:"forms"}})]},proxy:true},{key:"footer",fn:function(){return [_c('PortalTarget',{attrs:{"name":"portfolio-generator-modal-footer"}})]},proxy:true}]),model:{value:(_vm.showGenerator),callback:function ($$v) {_vm.showGenerator=$$v},expression:"showGenerator"}})],1),_vm._v(" "),_c('save-modal',_vm._b({},'save-modal',{
        loadedPortfolio: _vm.loadedPortfolio,
        type: 'agriculture'
      },false))]},proxy:true}])},'dashboard',{
    busy: _vm.busy,
    hasData: Boolean(_vm.data),
    isDirty: _vm.isDirty
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }