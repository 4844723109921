<template>
  <div class="email-confirmation-content">
    <div class="email-confirmation-content__container">
      <div class="email-confirmation-content__alert-wrapper">
        <div v-show="showAlert" class="email-confirmation-content__alert">
          <IconInfo />
          <span class="email-confirmation-content__alert-text">
            {{ $t('auth.confirmation_email.email_resent') }}
          </span>
        </div>
      </div>
      <icon-tick
        class="email-confirmation-content__icon"
        color="black"
      />
      <div class="email-confirmation-content__heading">
        {{ $t('auth.confirmation_email.verify_account') }}
      </div>
      <div class="email-confirmation-content__body">
        {{ $t('auth.confirmation_email.instructions_sent_to') }}
        <b>{{ email }}</b>.
        {{ $t('auth.confirmation_email.follow_instructions') }}
      </div>
      <div>
        {{ $t('auth.confirmation_email.did_not_receive_email') }}
        <a @click="resendEmailConfirmation" class="email-confirmation-content__link">
          {{ $t('auth.confirmation_email.click_to_resend') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconTick from '~/icons/IconTick.vue'
import IconInfo from '~/icons/IconInfo'

export default {
  components: {
    IconTick,
    IconInfo
  },
  model: {
    event: 'changed'
  },
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showAlert: false
    }
  },
  methods: {
    resendEmailConfirmation() {
      this.$http.post(`/${this.$i18n.locale}/users/confirmation`, { user: { email: this.email } })
        .then(() => {
          this.showEmailSentAlert()
        })
        .catch()
    },
    showEmailSentAlert() {
      this.showAlert = true
      setTimeout(() => {
        this.showAlert = false
      }, 2000)
    }
  }
}
</script>
