<template>
  <div class="portfolios-list">
    <div class="portfolios-list__header">
      <h2 class="portfolios-list__title">
        {{ $t('portfolios.title') }}
        <DashboardTooltip
          v-if="hasInvalidCompanies"
          class="portfolios-list__tooltip"
          type="warning"
          :tooltip="invalidCompanyWarning"
        />
      </h2>
      <div class="portfolios-list__search">
        <input
          v-model.trim="name"
          type="text"
          class="portfolios-list__search-bar"
          :placeholder="$t('portfolios.search.placeholder')"
        >
      </div>
    </div>
    <div class="portfolios-list__body">
      <cards
        v-bind="{ filters: { name } }"
        @update:hasInvalidCompanies="setHasInvalidCompanies"
      />
    </div>
  </div>
</template>

<script>
import Cards from './Cards'
import DashboardTooltip from '~/components/dashboard/DashboardTooltip'

export default {
  components: {
    Cards,
    DashboardTooltip
  },

  data () {
    return {
      name: undefined,
      hasInvalidCompanies: false
    }
  },

  computed: {
    invalidCompanyWarning () {
      return this.$t('portfolios.invalid_company_warning')
    }
  },

  methods: {
    setHasInvalidCompanies (value) {
      this.hasInvalidCompanies = value
    }
  }
}
</script>
