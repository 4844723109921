<template>
  <div class="v-password-input">
    <input
      v-model="inputValue"
      :type="inputType"
      v-bind="{ ...$attrs }"
    >
    <div
      class="v-password-input__toggler"
      tabindex="0"
      @click.prevent="toggleShowPassword"
      @keydown.space.prevent="toggleShowPassword"
    >
      <svg
        v-if="showPassword"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.989 13.994"
      >
        <path d="M11.994 10.995a3.966 3.966 0 01-2.827-1.172.5.5 0 01.706-.706 3 3 0 104.24-4.24.5.5 0 01.707-.706 4 4 0 01-2.827 6.825z" />
        <path d="M8.557 8.107a.5.5 0 01-.491-.413 3.906 3.906 0 01-.07-.7 3.958 3.958 0 014.695-3.928.5.5 0 11-.174.984 2.962 2.962 0 00-3.521 2.946 2.9 2.9 0 00.054.523.5.5 0 01-.4.579.44.44 0 01-.093.009z" />
        <path d="M11.991 13.993a15.286 15.286 0 01-6-1.566c-3.678-2.134-1.2-1.082-1.076-1.33s-1.068-1.146 1.321.276a15.521 15.521 0 005.757 1.62c4.943 0 9.632-4.707 10.823-6a23.491 23.491 0 00-5.95-4.651.5.5 0 11.459-.889 24.284 24.284 0 016.546 5.221.5.5 0 010 .646c-.23.272-5.718 6.673-11.88 6.673z M4.95 11.714a.492.492 0 01-.276-.084A18.559 18.559 0 01.09 7.283a.5.5 0 01.028-.609C.349 6.401 5.836 0 11.995 0a10.327 10.327 0 013.28.576 13.684 13.684 0 012.079.912c-.035.044-.121.975-.383.881a12.2 12.2 0 00-4.976-1.368c-4.979 0-9.7 4.775-10.849 6.024a18.288 18.288 0 004.08 3.772.5.5 0 01-.276.916z" />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.989 17.991"
      >
        <path d="M11.994 12.993a3.966 3.966 0 01-2.827-1.172.5.5 0 01.706-.706 3 3 0 104.24-4.24.5.5 0 01.707-.706 4 4 0 01-2.827 6.825zM8.557 10.105a.5.5 0 01-.491-.413 3.906 3.906 0 01-.07-.7 3.958 3.958 0 014.695-3.928.5.5 0 11-.174.984 2.962 2.962 0 00-3.521 2.946 2.9 2.9 0 00.054.523.5.5 0 01-.4.579.44.44 0 01-.093.009z" />
        <path d="M11.991 15.991a12.5 12.5 0 01-5.459-1.3.5.5 0 01.434-.9 11.525 11.525 0 005.026 1.2c4.943 0 9.632-4.707 10.823-6a23.491 23.491 0 00-5.949-4.651.5.5 0 11.459-.889 24.284 24.284 0 016.546 5.221.5.5 0 010 .646c-.23.272-5.718 6.673-11.88 6.673zM4.95 13.712a.492.492 0 01-.276-.084A18.559 18.559 0 01.09 9.281a.5.5 0 01.028-.609c.231-.273 5.718-6.674 11.877-6.674a10.327 10.327 0 013.28.576.5.5 0 01.314.633.493.493 0 01-.633.314 9.355 9.355 0 00-2.961-.523c-4.979 0-9.7 4.775-10.849 6.024a18.288 18.288 0 004.08 3.773.5.5 0 01-.276.916z" />
        <path d="M3.5 17.991a.5.5 0 01-.354-.853L20.138.146a.5.5 0 11.706.707L3.852 17.845a.5.5 0 01-.352.146z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showPassword: false,
      inputValue: this.value,
    }
  },
  computed: {
    inputType () {
      return this.showPassword ? 'text' : 'password'
    }
  },
  watch: {
    inputValue (value) {
      this.$emit('input', value)
    }
  },
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    }
  },
}
</script>