<template>
  <div class="portfolio-option__tile">
    <div
      v-if="option.highlight === true"
      class="portfolio-option__ribbon"
    >
      <span class="portfolio-option__ribbon-span" />
      <span class="portfolio-option__ribbon-span" />
    </div>
    <h4 class="portfolio-option__title">
      {{ option.principle }}
    </h4>
    <p
      v-if="option.description"
      class="portfolio-option__text"
    >
      {{ option.description }}
    </p>
    <a
      v-if="option.resource"
      :href="option.resource"
      target="_blank"
      class="portfolio-option__link"
    >
      {{ t.options.link_text }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  },

  computed: {
    t () {
      return this.$t('biodiversity_goals.dashboard.shared.components.portfolio_options')
    }
  }
}
</script>