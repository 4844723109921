<template>
  <div
    class="filter__dropdown"
    :class="{'filter__dropdown--active': isActive}"
  >
    <button 
      :id="mixinTriggerId"
      aria-haspopup="true"
      :aria-expanded="isActive"
      :aria-controls="mixinModalId"
      class="filter__dropdown-toggle hover--pointer flex-inline flex-v-center no-padding"
      @click="toggleDropdown"
    >
      <label
        :for="mixinModalId"
        :title="truncate(filterOptions.label) === filterOptions.label ? filterOptions.label : undefined"
        class="filter__select filter__select--dropdown hover--pointer no-margin"
      >
        {{ truncate(filterOptions.label) }}</label>
    </button>
    <div class="filter__dropdown-wrapper">
      <menu
        :id="mixinModalId"
        class="filter__dropdown-menu ul--unstyled"
      >
        <a
          class="filter__dropdown-item"
          :id="'all'"
          v-html="noFilterLabel()"
          v-show="true"
          @click="filter('all')"
        />
        <a
          class="filter__dropdown-item"
          v-for="dropdownItem in filterOptions.options"
          :key="dropdownItem"
          v-html="dropdownItem"
          @click="filter(dropdownItem)"
        />
      </menu>
    </div>
  </div>
</template>

<script>
import { truncate } from 'lodash'
import mixinFocusCapture from '~/mixins/mixin-focus-capture'
import mixinPopupCloseListeners from '~/mixins/mixin-popup-close-listeners'

const TRUNCATE_THRESHOLD = 24

export default {

  mixins: [
    mixinFocusCapture({toggleVariable: 'isActive', closeCallback: 'closeDropdown', openCallback: 'openDropdown'}),
    mixinPopupCloseListeners({closeCallback: 'closeDropdown'})
  ],

  props: {
    filterOptions: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      isActive: false
    }
  },

  computed: {
    mixinModalId () {
      return `filter-dropdown`
    },

    mixinTriggerId () {
      return `filter-toggle-dropdown`
    }
  },

  methods: {
    truncate (text) {
      return truncate(text, {
        length: TRUNCATE_THRESHOLD
      })
    },

    closeDropdown () {
      this.isActive = false
    },
    openDropdown () {
      this.isActive = true
    },
    toggleDropdown (e) {
      this.isActive ? this.closeDropdown(e) : this.openDropdown(e)
    },
    filter (e) {
      this.$parent.$emit('select-filter', e)
    },
    noFilterLabel() {
      return this.$t('labels.all')
    }
  }
}
</script>