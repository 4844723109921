<template>
  <div class=" section-padding">
    <div class="data-resources__filters">
      <v-select-searchable 
        class="data-resources__filter" 
        :config="entitySelectConfig" 
        :selected="selectedEntity" 
        :options="currentDataResourcesSet.data_resources"
        @update:selected-option="updateEntity"
      />

      <v-select-searchable 
        class="data-resources__filter" 
        :config="regionSelectConfig" 
        :selected="selectedDataResourcesSet" 
        :options="dataResourceSets"
        @update:selected-option="updateDataResourcesSet"
      />
    </div>

    <template v-if="currentDataResourceFeatures.length">
      <template v-for="feature in currentDataResourceFeatures">
        <div :key="`data-resource-viewier-${feature.name.replace(' ', '_')}`">
          <h3 class="list-item list-page__item">
            {{ feature.name }}
          </h3>

          <a
            v-for="dataset in feature.datasets"
            :key="dataset.name"
            :href="dataset.url"
            :title="data_resources_t.data_resource_link"
            target="_blank"
            class="list-item list-item--white"
          >
            <span>
              <div class="data-resources__name paragraph paragraph--no-margin">{{ dataset.name }}</div>
              <div
                v-if="showNotInMapLabel(dataset)"
                class="data-resources__non-map-label flex-inline flex-v-center"
              >
                <span class="info-icon" />
                <span class="paragraph paragraph--no-margin">{{ data_resources_t.not_in_map }}</span>
              </div>
            </span>
            <span class="data-resources__link flex-no-shrink list-item--arrow flex-hs-end">{{ data_resources_t.data_resource_link }}</span>
          </a>
        </div>
      </template>
    </template>

    <div 
      v-else
      class="data-resources__no-datasets list-item list-page__item"
    >
      {{ data_resources_t.no_datasets }}
    </div>
  </div>
</template>

<script>
import VSelectSearchable from '~/components/v-select/VSelectSearchable'

export default {
  components: {
    VSelectSearchable
  },

  props: {
    dataResourceSets: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      entitySelectConfig: { id: 'data-resources-viewer-select-entity' },
      regionSelectConfig: { id: 'data-resources-viewer-select-region' },
      data_resources_t: this.$t('data_resources'),
      selectedEntity: '',
      selectedDataResourcesSet: ''
    }
  },

  computed: {
    currentDataResourceFeatures () {
      return this.currentDataResources.features.filter(this.showFeature)
    },

    currentDataResources () {
      return this.currentDataResourcesSet.data_resources.find(entity => entity.id === this.selectedEntity)
    },

    currentDataResourcesSet () {
      return this.dataResourceSets.find(set => set.id === this.selectedDataResourcesSet)
    }
  },

  created () {
    this.updateDataResourcesSet(this.dataResourceSets.find(op => op.id === 'global').id)
    this.updateEntity(this.currentDataResourcesSet.data_resources[0].id)
  },

  methods: {
    updateDataResourcesSet(id) {
      this.selectedDataResourcesSet = id
    },

    updateEntity(id) {
      this.selectedEntity = id
    },

    showFeature(feature) {
      return Boolean(feature.datasets.length)
    },

    showNotInMapLabel (dataset) {
      return !dataset.on_map
    }
  }
}
</script>
