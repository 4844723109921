<template>
  <div>
    <section v-for="filterGroup in filterGroups">
      <tabs
        :id="filterGroup.id"
        modifier="resources"
        @tab-changed="selectTab($event)"
      >
        <tab
          :id="'all'"
          :title="noFilterLabel()" 
          :active="true"
        >
        </tab>

        <template v-for="option in filterGroup.options">
          <tab
            :id="option.id.toString()"
            :title="option.title"
          >
          </tab>
        </template>
        
      </tabs>
    </section>
  </div>
</template>
  
<script>
import Tab from '~/components/tabs/Tab'
import Tabs from '~/components/tabs/Tabs'
  export default {
    name: 'listing-page-filter-tabs',

    components: {
      Tab,
      Tabs
    },

    props: {
      filterGroups: {
        required: true,
        type: Array // [{:id=>"resource_types", :options=>[{:id=>1, :title=>"Reports & Briefings"}, {:id=>2, :title=>"Case studies"}, {:id=>3, :title=>"Tutorials"}, {:id=>4, :title=>"Webinars"}, {:id=>5, :title=>"Additional resources"}], :title=>"Resource Types", :type=>"checkbox"}]
      }
    },
  
    methods: {
      selectTab (tab) {
        this.$parent.$emit('select-tab', tab)
      },
      noFilterLabel() {
        return this.$t('labels.all')
      }
    }
  }
  </script>