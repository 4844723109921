<template>
  <div 
    :class="['grid-card', { 'grid-card--link': image }]"
  >
    <div 
      :alt="title"
      class="grid-card__image"
      :style="{ backgroundImage: `url(${image})` }"
    />
    <div class="grid-card__content">
      <div
        class="grid-card__taglines-group"
      >
        <p
          v-for="(catGroup, group) in taglines"
          :key="group"
          class="grid-card__taglines"
        >
          <span
            v-for="(category, index) in catGroup"
            :key="index"
          > {{ titleForCategory(group,category) }}</span>
        </p>
      </div>
      <h2
        class="grid-card__title"
        v-html="title"
      />
      <p
        class="grid-card__description"
        v-html="description"
      />

      <a
        class="button--arrow-right"
        :href="fullResource"
        target="_blank">
          {{ textButton_t }}
          <span class="grid-card__button-icon"><IconRightArrow/></span>
      </a>
    </div>
  </div>
</template>

<script>
import IconRightArrow from '../../icons/IconRightArrow.vue'
export default {
  name: 'listing-page-card',

  components: { IconRightArrow },

  props: {
    date: {
      type: String,
      required: true
    },
    image: String,
    description: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    fullResource: {
      type: String,
      required: true
    },
    categories: {
      type: Object
    }
  },

  computed: {
    class () {
      
    },
    textButton_t () {
      return this.$t('buttons.view')
    },
    taglines () {
      return this.categories ? this.categories : [[this.date]]
    },
  },

  methods: {
    // return titelized category or date
    titleForCategory (group, category) {
      return this.categories ? this.$t(`custom_categories.${group}.items.${category}`) : category
    }
  }
}
</script>

