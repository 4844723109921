<template>
  <carousel>
    <div
      v-for="(slide, index) in carouselContent.results"
      :key="index"
      class="carousel__cell"
    >
      <div class="carousel__cell-content">
        <div class="carousel__cell-tagline">
          {{ latestNewsText() }}
        </div>
        <div class="carousel__cell-heading">
          {{ slide.title }}
        </div>
        <div
          class="carousel__cell-description"
        >
          {{ slide.description }}
        </div>
        <a
          class="carousel__cell-button"
          :href="slide.full_resource"
        >
          {{ viewButtonText() }}
          <span class="carousel__cell--button-icon"><IconRightArrow/></span>
        </a>
      </div>
      <div 
        class="carousel__cell-image"
        :style="imageStyling(slide.image)">
      </div>
      <div class="carousel__cell-overlay" />
    </div>
  </carousel>
</template>

<script>
import Carousel from '../carousel/Carousel.vue'
import IconRightArrow from '../../icons/IconRightArrow.vue'

export default {
  name: 'header-carousel',

  components: {
    Carousel,
    IconRightArrow
  },

  props: {
    carouselContent: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  methods: {
    latestNewsText() {
      return this.$t('labels.latest-news')
    },
    viewButtonText() {
      return this.$t('buttons.view')
    },
    imageStyling (img) {
      return `background-image: url(${img})`
    }
  }
}
</script>
