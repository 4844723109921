var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"portfolio-generator-form portfolio-generator-form--agriculture",class:{
    'portfolio-generator-form--invalid': _vm.errors.any()
  }},[_c('div',{staticClass:"portfolio-generator-form__legend"},[_vm._v("\n    "+_vm._s(_vm.$parent.t.legend)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"portfolio-generator-form__data"},[_c('button',{staticClass:"portfolio-generator-form__close",on:{"click":function($event){$event.preventDefault();return _vm.onCloseButtonClick($event)}}},[_c('IconClose',{staticClass:"portfolio-generator-form__close-icon"})],1),_vm._v(" "),_c('VErrors',{attrs:{"errors":_vm.errors.get('base')}}),_vm._v(" "),_c('div',{staticClass:"portfolio-generator-form__section portfolio-generator-form__section--country"},[_c('VField',{attrs:{"error":_vm.errors.has('country')}},[_c('label',{staticClass:"portfolio-generator-form__label",attrs:{"for":_vm.id(_vm.index, 'country')}},[_vm._v("\n          "+_vm._s(_vm.$parent.t.labels.location)+"\n        ")]),_vm._v(" "),_c('VSelectSearchable',{staticClass:"portfolio-generator-form__input",attrs:{"config":_vm.countrySelectConfig,"options":_vm.countries,"disabled":_vm.$parent.busy,"selected":_vm.form.country},on:{"update:selected-option":function($event){return _vm.onUpdate('country', $event)}}}),_vm._v(" "),_c('VErrors',{attrs:{"errors":_vm.errors.get('country')}})],1)],1),_vm._v(" "),_vm._l((_vm.cropTypes),function(cropType,index){return _c('div',{key:cropType,class:("portfolio-generator-form__section portfolio-generator-form__section--" + cropType)},[_c('CropTypeInput',_vm._b({on:{"change":_vm.onCropTypeInputUpdate}},'CropTypeInput',{
          busy: _vm.$parent.busy,
          cropType: cropType,
          errors: _vm.errors,
          form: _vm.form,
          index: index,
          initalizeExpanded: index === 0
        },false))],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }