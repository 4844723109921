<template>
  <div :class="`dashboard-tooltip dashboard-tooltip--${type}`">
    <icon-triangular-warning-sign
      v-if="type == 'warning'"
      class="dashboard-tooltip__icon"
    />

    <icon-info
      v-else
      class="dashboard-tooltip__icon"
    />

    <span class="dashboard-tooltip__text">
      {{ tooltip }}
    </span>
  </div>
</template>

<script>
import IconInfo from '~/icons/IconInfo'
import IconTriangularWarningSign from '~/icons/IconTriangularWarningSign'

export default {
  components: { IconInfo, IconTriangularWarningSign },

  props: {
    tooltip: {
      type: String,
      required: true
    },

    type: {
      type: String,
      default: 'info'
    }
  }
}
</script>