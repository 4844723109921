<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 82 82"
  >
    <path
      d="M82 26.092V3.727A3.727 3.727 0 0078.272 0H55.91a3.727 3.727 0 00-3.728 3.727v9.318a39.169 39.169 0 00-37.856 29.382l-3.875-.682a1.865 1.865 0 00-1.987 2.68L12.5 52.4a14.9 14.9 0 1017.189 16.555h13.174v3.727a1.864 1.864 0 002.985 1.49l6.515-4.889a14.9 14.9 0 1016.589-16.97V39.137h3.73a1.864 1.864 0 001.49-2.982l-4.755-6.335h8.849A3.732 3.732 0 0082 26.092zM14.91 75.545c-5.055.2-8.455-4.343-8.455-8.452s3.487-8.552 8.455-8.455a8.453 8.453 0 110 16.907zm60.638-8.453a8.776 8.776 0 01-8.455 8.452c-4.82.074-8.452-4.343-8.452-8.452a8.453 8.453 0 1116.907 0zM59.834 38.106a1.865 1.865 0 001.666 1.029h3.728v13.174A14.922 14.922 0 0052.365 64.9l-6.518-4.89a1.864 1.864 0 00-2.982 1.49v3.727H29.691a14.921 14.921 0 00-12.942-12.921.915.915 0 00.026-.124v-.019l5.641-5.281a1.864 1.864 0 00-.951-3.2l-3.46-.611a35.441 35.441 0 0134.178-26.3v9.319a3.726 3.726 0 003.728 3.727h8.851l-4.752 6.336a1.856 1.856 0 00-.176 1.953zm15.714-14.743H58.637V6.455h16.911z"
      :fill="props.color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: '#000',
      type: String,
    },
  },
}
</script>
