<template>
  <div 
    class="listing__card-grid"
  >
    <listing-page-card
      v-if="hasResults"
      v-for="results, index in results"
      :key="results.id"
      :date="results.date"
      :image="results.image"
      :fullResource="results.full_resource"
      :textButton="button_text_t"
      :description="results.description"
      :title="results.title"
      :categories="results.categories"
    />

    <p 
      v-show="!hasResults"
      v-html="no_results_t"
      class="search__results-none"
    />
  </div>
</template>

<script>
import ListingPageCard from '../listing/ListingPageCard.vue'

export default {
  name: 'listing-page-list',

  components: { 
    ListingPageCard
  },
  
  props: {
    results: {
      type: Array // { title: String, total: Number, results: [{ date: String, image: String, summary: String, title: String, url: String }
    },
    template: {
      required: true,
      type: String
    }
  },

  computed: {
    hasResults () {
      return this.results.length > 0
    },
    no_results_t () {
      return this.$t('labels.no_results')
    },
    button_text_t () {
      return this.$t('buttons.view')
    }
  }
}
</script>